import components from "@/app/components/globalComponents";
import { storyblokInit, apiPlugin } from "@storyblok/react/rsc";

export const getStoryblokApi = storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_KEY,
  use: [apiPlugin],
  apiOptions: {
      region: 'eu'
  },
  components
});