import React, { useEffect, useState } from "react"
import Link from "next/link"
import Image from "next/image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import styles from './Navigation.module.css'
import { faCreditCardFront, faHeadset, faEnvelopeDot, faMagnifyingGlass } from "@awesome.me/kit-d4c82d9167/icons/classic/regular"
import { faPhone, faBars, faXmarkLarge } from "@awesome.me/kit-d4c82d9167/icons/classic/light"
import Container from "../container"
import clsx from "clsx"
import CustomLink from "../link"
import { AlertDialog, AlertDialogContent, AlertDialogTrigger } from "../alert-dialog/AlertDialog"
import PhoneNumber from "./PhoneNumber"
import { getOpeningTimes, getPhoneNumber } from "@/utils/queries"
import { getCookie } from "@/utils/cookies"
import SmartCodeModal from "./SmartCodeModal"
import OpeningHours from "./OpeningHours"
import { useMobileMenu } from './MobileMenuContext'

type Props = {
  isVisible: boolean,
  children: React.ReactNode
}

type DayTime = {
  opening: string
  closing: string
}

export type OpeningTimes = {
  Monday: DayTime
  Tuesday: DayTime
  Wednesday: DayTime
  Thursday: DayTime
  Friday: DayTime
  Saturday: DayTime
  Sunday: DayTime
}

export type InformationTimes = {
  sales: OpeningTimes
  support: OpeningTimes
}

const NavigationParent = ({ isVisible, children }: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [data, setData] = useState<InformationTimes | undefined>()
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [smartCodeModalOpen, setSmartCodeModalOpen] = useState(false);
  const { toggleMobileMenu, mobileMenuOpen } = useMobileMenu();


  useEffect(() => {
    const fetchData = async () => {
      const response = await getOpeningTimes();
      const phoneNumberResponse = await getPhoneNumber(getCookie('aff'));
      setData(response);
      setPhoneNumber(phoneNumberResponse);
    }
    fetchData()
  }, [])

  const checkDay = (type: 'support' | 'sales') => {
    if (!data) return;

    const currentDay = new Date().getDay();
    const currentDayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][currentDay];

    const { closing } = data[type][currentDayName as keyof OpeningTimes];
    return closing;
  }

  return (
    <div className={clsx(
      styles.navigationWrapper,
      {
        [styles.navigationWrapperOpen]: !isVisible
      }
    )}>
      <div className={clsx(styles.topBar, { [styles.topColorChange]: !isVisible })}>
        <Container>
          <div className={styles.topBarContainer}>
            <button aria-label="Smart Code" onClick={() => setSmartCodeModalOpen(true)}
              className={styles.topBarButton}>
              <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} /> Smart Code
            </button>
            <Link href="/make-payment"
              className={styles.topBarButton}>
              <FontAwesomeIcon icon={faCreditCardFront as IconProp} /> Make Payment
            </Link>
            <Link href="/knowledge-base"
              className={styles.topBarButton}>
              <FontAwesomeIcon icon={faHeadset as IconProp} /> Customer Support
            </Link>
            <button aria-label="Exclusive Offers" onClick={() => {
              (document.getElementById("signup-modal") as HTMLDialogElement)?.showModal()
            }} className={styles.topBarButton}>
              <FontAwesomeIcon icon={faEnvelopeDot as IconProp} /> Get Exclusive Offers
            </button>
          </div>
          <SmartCodeModal
            isOpen={smartCodeModalOpen}
            onOpenChange={setSmartCodeModalOpen}
          />
        </Container>
      </div>
      <div className={styles.navigation}>
        <Container>
          <div className={styles.wrapper}>
            <button aria-label="Menu"
              className={clsx(styles.menuButton, mobileMenuOpen && styles.menuButtonOpen)}
              onClick={() => toggleMobileMenu()}>
              {mobileMenuOpen ?
                <FontAwesomeIcon icon={faXmarkLarge as IconProp} /> :
                <FontAwesomeIcon icon={faBars as IconProp} />
              }
            </button>
            <CustomLink href="/" className={styles.logoContainer}>
              <Image
                width={250}
                height={49}
                className={styles.logo}
                src="https://a.storyblok.com/f/306333/300x52/9ee8688c42/cn-2024-red-circle.svg"
                alt="Cruise Nation Logo"
              />
            </CustomLink>
            {children}
            <AlertDialog open={openModal} onOpenChange={setOpenModal}>
              <AlertDialogTrigger ariaLabel="Contact Cruise Nation">
                <div className={styles.phoneButton}>
                  <FontAwesomeIcon icon={faPhone as IconProp} />
                </div>
                <div className={styles.text}>
                  <OpeningHours openingTimes={data} type="sales" className={'sales-opening-times'} />
                  <p className={styles.phoneNumber}>{phoneNumber}</p>
                </div>
              </AlertDialogTrigger>
              <AlertDialogContent className={styles.alertDialogContent}>
                <PhoneNumber setOpenModals={setOpenModal} data={data} phoneNumber={phoneNumber} />
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default NavigationParent