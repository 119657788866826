"use client"
import { ReactNode } from "react";
import { getStoryblokApi } from "@/utils/lib/storyblok";

type Props = { children: ReactNode };
const StoryblokProvider = ({ children }: Props) => {
    getStoryblokApi();
    return children;
};

export default StoryblokProvider;
